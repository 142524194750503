import React from 'react'

export default {
    candidates: {
        path: <path d="M18.5 19.667h5.833v-2.334a3.5 3.5 0 0 0-6.248-2.166m.415 4.5l-.415-4.5m.415 4.5H6.833m11.667 0v-2.334c0-.765-.147-1.496-.415-2.166m0 0a5.836 5.836 0 0 0-10.836 0m10.836 0H7.249m-.416 4.5H1v-2.334a3.5 3.5 0 0 1 6.249-2.166m-.416 4.5l.416-4.5m-.416 4.5v-2.334c0-.765.147-1.496.416-2.166M16.167 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm7 3.5A2.333 2.333 0 1 1 18.5 8a2.333 2.333 0 0 1 4.667 0zM6.833 8a2.333 2.333 0 1 1-4.666 0 2.333 2.333 0 0 1 4.666 0z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{fill: "transparent"}}/>,
    },
    voting: {
        path: <path d="M7 10l2 2 4-4m6 2a9.0004 9.0004 0 0 1-5.5558 8.3149A9.0004 9.0004 0 0 1 3.636 16.364 9.0011 9.0011 0 0 1 1 10a9 9 0 1 1 18 0z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{fill: "transparent"}}/>
    },
    results: {
        path: <path d="M8 9h4M3 5h14H3zm0 0a2 2 0 1 1 0-4h14a2 2 0 0 1 0 4H3zm0 0v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5H3z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{fill: "transparent"}}/>
    },
    forward: {
        path: <path d="M13 3.99998H1M10.3333 1.33331L13 3.99998L10.3333 1.33331ZM13 3.99998L10.3333 6.66665L13 3.99998Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{fill: "transparent"}}/>,
        viewBox: "0 0 14 8"
    },
    back: {
        path: <path d="M8.5 14.4922L1 8.10639M1 8.10639L8.5 1.72058M1 8.10639H23.375" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
        viewBox: "0 0 25 16"
    },
    email: {
        path: <path d="M2.2 0C0.9845 0 0 0.9845 0 2.2V15.4C0 16.6155 0.9845 17.6 2.2 17.6H19.8C21.0155 17.6 22 16.6155 22 15.4V2.2C22 0.9845 21.0155 0 19.8 0H2.2ZM2.2 2.2H19.8V3.30215L11 8.8L2.2 3.30215V2.2ZM2.2 5.50215L11 11L19.8 5.50215V15.4H2.2V5.50215Z" fill="currentColor"/>,
        viewBox: "0 0 22 18"
    },
    instagram: {
        path: <path d="M6.66667 0C2.99067 0 0 2.99067 0 6.66667V17.3333C0 21.0093 2.99067 24 6.66667 24H17.3333C21.0093 24 24 21.0093 24 17.3333V6.66667C24 2.99067 21.0093 0 17.3333 0H6.66667ZM6.66667 2.66667H17.3333C19.5387 2.66667 21.3333 4.46133 21.3333 6.66667V17.3333C21.3333 19.5387 19.5387 21.3333 17.3333 21.3333H6.66667C4.46133 21.3333 2.66667 19.5387 2.66667 17.3333V6.66667C2.66667 4.46133 4.46133 2.66667 6.66667 2.66667ZM18.6667 4C18.313 4 17.9739 4.14048 17.7239 4.39052C17.4738 4.64057 17.3333 4.97971 17.3333 5.33333C17.3333 5.68696 17.4738 6.02609 17.7239 6.27614C17.9739 6.52619 18.313 6.66667 18.6667 6.66667C19.0203 6.66667 19.3594 6.52619 19.6095 6.27614C19.8595 6.02609 20 5.68696 20 5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3594 4.14048 19.0203 4 18.6667 4ZM12 5.33333C8.324 5.33333 5.33333 8.324 5.33333 12C5.33333 15.676 8.324 18.6667 12 18.6667C15.676 18.6667 18.6667 15.676 18.6667 12C18.6667 8.324 15.676 5.33333 12 5.33333ZM12 8C14.2053 8 16 9.79467 16 12C16 14.2053 14.2053 16 12 16C9.79467 16 8 14.2053 8 12C8 9.79467 9.79467 8 12 8Z" fill="currentColor"/>,
        viewBox: "0 0 24 24"
    },
    snapchat: {
        path: <path d="M11 0C4.4004 0 4.4004 6.5996 4.4004 6.5996V7.69954C4.4004 7.69954 4.37565 7.69739 4.33165 7.69739C3.95768 7.69739 2.20053 7.77492 2.20053 9.23343C2.20053 10.9328 4.06695 10.9993 4.36173 10.9993H4.4004C4.4004 12.0993 2.58295 13.3866 1.39062 13.6847C0.279686 14.1159 0.014963 14.8521 0.000663863 15.4098C-0.00813561 15.7431 0.072675 16.0121 0.116673 16.1166C0.531347 17.09 1.763 17.5925 2.96963 17.5925C3.01142 19.1753 4.15369 19.7988 4.91814 19.7988C5.79149 19.7988 6.71628 19.3713 6.71628 19.3713C6.76137 19.4087 8.16767 20.8987 11 20.8987C13.8323 20.8987 15.2397 19.4087 15.2859 19.3713C15.2859 19.3713 16.2074 19.7988 17.0819 19.7988C17.8463 19.7988 18.9886 19.1753 19.0304 17.5925C20.237 17.5925 21.4687 17.09 21.8833 16.1166C21.9273 16.0121 22.0081 15.7431 21.9993 15.4098C21.985 14.8521 21.7203 14.1159 20.6094 13.6847C19.4171 13.3866 17.5996 12.0993 17.5996 10.9993H17.6383C17.9331 10.9993 19.7995 10.9328 19.7995 9.23343C19.7995 7.77492 18.0423 7.69739 17.6683 7.69739C17.6244 7.69739 17.5996 7.69954 17.5996 7.69954V6.5996C17.5996 6.5996 17.5996 0 11 0ZM11 2.19987C15.0181 2.19987 15.3832 5.57996 15.3997 6.5996V10.9993C15.3997 13.0496 17.3213 14.626 18.9294 15.3926H16.8864L16.8305 17.5366C16.8305 17.5476 16.8284 17.5579 16.8284 17.5689C16.6062 17.5238 16.3505 17.4393 16.2075 17.3755L14.9658 16.8019L13.9002 17.6634L13.8121 17.7364L13.7434 17.8009C13.6488 17.8922 12.7588 18.6989 11 18.6989C9.24121 18.6989 8.35016 17.8933 8.25446 17.8009L8.17068 17.7192L8.09764 17.6612L7.03637 16.8213L5.80539 17.3691C5.6536 17.4373 5.39493 17.5238 5.17164 17.5689C5.17054 17.559 5.16949 17.5476 5.16949 17.5366L5.11364 15.3948H3.0706C4.6787 14.627 6.60027 13.0496 6.60027 10.9993V6.5996C6.60027 5.86485 6.81255 2.19987 11 2.19987Z" fill="currentColor"/>,
        viewBox: "0 0 22 21"
    },
    facebook: {
        path: <path d="M9.5 6.31835H6.31829V4.51312C6.31829 3.58162 6.39411 2.99493 7.72907 2.99493H9.41515V0.124617C8.59468 0.0397714 7.76969 -0.00174881 6.9438 5.64133e-05C4.49501 5.64133e-05 2.70784 1.49569 2.70784 4.24143V6.31835H0V9.9288L2.70784 9.9279V18.0523H6.31829V9.92609L9.0857 9.92519L9.5 6.31835Z" fill="currentColor"/>,
        viewBox: "0 0 10 19"
    },
    menu: {
        path: <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" fill="currentColor"/>,
        viewbox: "0 0 20 20"
    },
    dashboard: {
        path: <path d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>,
        viewbox: "0 0 24 24"
    },
    decreasing_arrow: {
        path: <><circle cx="11" cy="11" r="10" fill="#FACDCD"/><path d="M14.1499 8.35005L14.1499 14.2495M14.1499 14.2495H8.44971M14.1499 14.2495L7.94971 7.8501" stroke="#D64545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></>,
        viewBox: "0 0 23 23"
    },
    increasing_arrow: {
        path: <><circle cx="11" cy="11" r="10" fill="#BEF8FD"/><path d="M8.00044 7.29985L13.8999 7.29983M13.8999 7.29983V13M13.8999 7.29983L7.50049 13.5" stroke="#14919B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></>,
        viewBox: "0 0 23 23"
    },
    settings_custom: {
        path: <><path d="M8.325 2.317C8.751 0.561 11.249 0.561 11.675 2.317C11.7389 2.5808 11.8642 2.82578 12.0407 3.032C12.2172 3.23822 12.4399 3.39985 12.6907 3.50375C12.9414 3.60764 13.2132 3.65085 13.4838 3.62987C13.7544 3.60889 14.0162 3.5243 14.248 3.383C15.791 2.443 17.558 4.209 16.618 5.753C16.4769 5.98466 16.3924 6.24634 16.3715 6.51677C16.3506 6.78721 16.3938 7.05877 16.4975 7.30938C16.6013 7.55999 16.7627 7.78258 16.9687 7.95905C17.1747 8.13553 17.4194 8.26091 17.683 8.325C19.439 8.751 19.439 11.249 17.683 11.675C17.4192 11.7389 17.1742 11.8642 16.968 12.0407C16.7618 12.2172 16.6001 12.4399 16.4963 12.6907C16.3924 12.9414 16.3491 13.2132 16.3701 13.4838C16.3911 13.7544 16.4757 14.0162 16.617 14.248C17.557 15.791 15.791 17.558 14.247 16.618C14.0153 16.4769 13.7537 16.3924 13.4832 16.3715C13.2128 16.3506 12.9412 16.3938 12.6906 16.4975C12.44 16.6013 12.2174 16.7627 12.0409 16.9687C11.8645 17.1747 11.7391 17.4194 11.675 17.683C11.249 19.439 8.751 19.439 8.325 17.683C8.26108 17.4192 8.13578 17.1742 7.95929 16.968C7.7828 16.7618 7.56011 16.6001 7.30935 16.4963C7.05859 16.3924 6.78683 16.3491 6.51621 16.3701C6.24559 16.3911 5.98375 16.4757 5.752 16.617C4.209 17.557 2.442 15.791 3.382 14.247C3.5231 14.0153 3.60755 13.7537 3.62848 13.4832C3.64942 13.2128 3.60624 12.9412 3.50247 12.6906C3.3987 12.44 3.23726 12.2174 3.03127 12.0409C2.82529 11.8645 2.58056 11.7391 2.317 11.675C0.561 11.249 0.561 8.751 2.317 8.325C2.5808 8.26108 2.82578 8.13578 3.032 7.95929C3.23822 7.7828 3.39985 7.56011 3.50375 7.30935C3.60764 7.05859 3.65085 6.78683 3.62987 6.51621C3.60889 6.24559 3.5243 5.98375 3.383 5.752C2.443 4.209 4.209 2.442 5.753 3.382C6.749 3.99 8.049 3.452 8.325 2.317Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 10C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10C7 9.20435 7.31607 8.44129 7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7C10.7956 7 11.5587 7.31607 12.1213 7.87868C12.6839 8.44129 13 9.20435 13 10V10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></>,
        viewBox: "0 0 20 20"
    },
    document: {
        path: <><circle cx="25" cy="25.7778" r="25" fill="#DCEEFB"/>
        <path fill="transparent" d="M20.3333 18.1112V28.7778C20.3333 29.4851 20.6143 30.1634 21.1144 30.6635C21.6145 31.1635 22.2928 31.4445 23 31.4445H31M20.3333 18.1112V15.4445C20.3333 14.7373 20.6143 14.059 21.1144 13.5589C21.6145 13.0588 22.2928 12.7778 23 12.7778H29.1147C29.4683 12.7779 29.8073 12.9184 30.0573 13.1685L35.9427 19.0538C36.1927 19.3038 36.3333 19.6429 36.3333 19.9965V28.7778C36.3333 29.4851 36.0524 30.1634 35.5523 30.6635C35.0522 31.1635 34.3739 31.4445 33.6667 31.4445H31M20.3333 18.1112H17.6667C16.9594 18.1112 16.2811 18.3921 15.781 18.8922C15.281 19.3923 15 20.0706 15 20.7778V34.1112C15 34.8184 15.281 35.4967 15.781 35.9968C16.2811 36.4969 16.9594 36.7778 17.6667 36.7778H28.3333C29.0406 36.7778 29.7189 36.4969 30.219 35.9968C30.719 35.4967 31 34.8184 31 34.1112V31.4445" stroke="#0F609B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></>,
        viewBox: "0 0 50 51"
    },
    folder: {
        path: <path d="M1 3V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H11L9 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V3Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>,
        viewBox: "0 0 20 16"
    },
    trash: {
        path: <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor"></path>,
        viewBox: "0 0 24 24"
    }
}